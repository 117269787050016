import { AppProps } from 'next/app';
import { NextPage } from 'next';
import App from 'next/app';
import 'index.css';
import React, { useEffect } from 'react';
import browserCookies from 'browser-cookies';
import i18nLib from '../i18n';

const isServer = typeof window === 'undefined';

// const removeJssStyles = () => {
//     const jssStyles = document.querySelector('#jss-server-side');
//     if (jssStyles) {
//         jssStyles.parentElement?.removeChild(jssStyles);
//     }
// };

//@ts-ignore
const MyApp: NextPage<AppProps> = ({ Component, pageProps }: AppProps) => {
    // useEffect(() => removeJssStyles(), []);
    const { i18n } = i18nLib.useTranslation();

    useEffect(() => {
        document.documentElement.lang = i18n.language || 'ru';
        if (browserCookies && !isServer && i18n.language) {
            // try {
            browserCookies.set('language', i18n.language || 'ru');
            // } catch (e) {
            //     console.log(e);
            // }
        }
    }, [i18n, i18n.language]);
    return <Component {...pageProps} />;
};

//@ts-ignore
MyApp.getInitialProps = async appContext => {
    //@ts-ignore
    const appProps = await App.getInitialProps(appContext);

    const { defaultProps } = (appContext as any).Component;

    if (defaultProps?.i18nNamespaces) {
        return { ...appProps, pageProps: { namespacesRequired: defaultProps?.i18nNamespaces } };
    }

    return { ...appProps };
};

export default i18nLib.appWithTranslation(MyApp);
